<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="loading['admin/getOrganisations']"
            @delete="deleteInstitution"
            @edit="editInstitution"
            responsive="sm"
            hover
        >
        </st-data-table>
        <select-organisation-modal
            ref="selectOrganisationModal"
            :organisation="selectedOrganisation"
            @organisationSelected="organisationSelected"
        ></select-organisation-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { OrganisationModel } from '@/modules/administrator/models/organisation-model';
    import Message from '@/shared/message/message';
    import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
    const { fields } = OrganisationModel;

    export default {
        name: 'OrganisationListTable',
        components: { SelectOrganisationModal },
        data() {
            return {
                presenter: OrganisationModel.presenter,
                selectedOrganisation: {}
            };
        },
        computed: {
            ...mapGetters({
                rows: 'administrator/orgList/rows',
                loading: 'shared/loading',
                orgPermissions: 'administrator/orgList/orgPermissions',
            }),
            fields() {
                return [
                    fields.name,
                    fields.description,
                    fields.status.extendField({ labelType: true }),
                    fields.is_test.extendField({ booleanType: true }),
                ]
            },
            actions() {
                const actions = [];
                if (this.orgPermissions.hasPermissionToEdit) {
                    actions.push({
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('INSTITUTION.MAIN_PAGE.TOOLTIPS.EDIT'),
                        customIcon: false,
                        type: 'primary'
                    })
                }
                if (this.orgPermissions.hasPermissionToDestroy) {
                    actions.push({
                        name: 'delete',
                        tooltipText: this.$t('INSTITUTION.MAIN_PAGE.TOOLTIPS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        type: 'danger'
                    })
                }

                return actions
            }
        },
        methods: {
            ...mapActions({
                doFetchFunctionaryAccounts: 'administrator/list/doFetchFunctionaryAccounts',
                deleteOrg: 'administrator/orgForm/deleteInstitution',
            }),
            async deleteInstitution(data) {
                let query = `organisation_id eq '${data.item.id}'`;
                const users = await this.doFetchFunctionaryAccounts({payload: {}, query});

                if (users.data.length) {
                    Message.warning('INSTITUTION.ERRORS.DELETE_ORG');
                } else {
                    this.deleteOrg(data.item.id)
                        .then(() => Message.success('INSTITUTION.SUCCESS.DELETE_ORG'))
                        .catch(() => Message.error('INSTITUTION.ERRORS.DELETE_ORG'));
                }
            },
            editInstitution(data) {
                this.selectedOrganisation = data.item;
                this.$refs.selectOrganisationModal.show();
            },
            organisationSelected() {
                this.$router.push({
                    name: 'institution',
                    params: this.selectedOrganisation,
                    query: { id: this.selectedOrganisation.id, tab: 'institution-details'},
                });
            },
        },
    };
</script>
