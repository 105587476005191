<template>
    <div>
        <st-modal
            id="modal-insitution"
            hide-header-delimiter
            hide-footer-delimiter
            size="md"
            hideable
            ref="st-organisation-modal"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-0 pb-0"> {{ $t('INSTITUTION.ADD_MODAL.HEADER') }}</p>
            </template>
            <template #body>
                <add-institution-form
                    ref="add-institution-form"
                    @cancelForm="hide"
                    @submit="submit"
                >
                </add-institution-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('INSTITUTION.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="addInstitution"
                    >
                        <span>{{ $t('INSTITUTION.BUTTON.ADD') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import AddInstitutionForm from './AddInstitutionForm.vue';

export default {
    name: 'AddInstitution',
    components: {
        AddInstitutionForm
    },
    methods: {
        hide() {
            this.$refs['st-organisation-modal'].hide();
        },
        show() {
            this.$refs['st-organisation-modal'].show();
        },
        addInstitution() {
            this.$refs['add-institution-form'].manageInstitution();
        },
        submit() {
            this.$emit('submit');
        }

    }
};
</script>

